exports.courseList = [
  'ART491', 'ART492',
  'BIB492',
  'BIO492', 'BIO494',
  'BUS410', 'BUS492',
  'CDV492',
  'CHE491', 'CHE492',
  'COS492',
  'ECO491', 'ECO492',
  'EDU480', 'EDU481',
  'ENG490', 'ENG491', 'ENG492',
  'FRE492',
  'HIS491', 'HIS492',
  'IDS491',
  'INS491', 'INS492',
  'MAT492',
  'MUS491', 'MUS492', 'MUS493',
  'PHI492',
  'PHY492',
  'POL491', 'POL492',
  'PSY492',
  'SOC490',
  'SPA492', 'SPM492',
  'THT491', 'THT492'
]
