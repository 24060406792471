export default {
  setStoplightConcernOptions: (state, concerns) => {
    state.stoplight.concernOptions = concerns
  },
  setStoplightScotsworkConcerns: (state, concerns) => {
    state.stoplight.scotsworkConcerns = concerns
  },
  setAlertTerm: (state, term) => {
    state.alert.term = term
  }
}
